* {
  box-sizing: border-box;
}

*:after {
  margin: 0;
}

*:before {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html {
  scroll-behavior: smooth;
}

main, footer {
  min-width: 1400px;
}

.MuiPhoneNumber-flagButton {
  width: 30px;
}

/*
Настраиваем цвета автозаполнения от браузера - фоновое заполнени инпутов и цвет шрифта
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
input[data-autocompleted],
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
}

*::-webkit-scrollbar {
  width: 4px; /* ширина для вертикального скролла */
  height: 4px; /* высота для горизонтального скролла */
  background-color: #FBFBFD;
}

/* ползунок скроллбара */
*::-webkit-scrollbar-thumb {
  background-color: #D1D1D1;
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #918a8a;
}

*::-webkit-scrollbar-button {
  display:none;
}

@media screen and (min-width: 1400px) {
  body {
   overflow-x: hidden;
  }
}
