.fetching_button {
    position: relative;
    background: #9EA4D6 !important;
    color: #fff !important;
    border-color: #9EA4D6 !important;
}

.fetching_button:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(-45deg, rgba(196, 200, 230, 0.6) 25%,
    transparent 25%,
    transparent 50%,
    rgba(196, 200, 230, 0.6) 50%,
    rgba(196, 200, 230, 0.6) 75%,
    transparent 75%,
    transparent);

    background-size: 25px 25px;
    animation: move 2s linear infinite;
    transition: background 0.3s;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

